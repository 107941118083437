import React, { useState, useEffect } from "react";
import { Route, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";

import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import SearchBox from "./SearchBox";
import { logout } from "../actions/userActions";
import CartView from "../components/CartView";
import MenuSmall from "../components/MenuSmall";
import { Helmet } from "react-helmet";


const Header = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };
  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/category.json')
      .then(response => response.json())
      .then(data => {
        setJsonData(data);

        console.log('Fetched JSON data:', data);
      })
      .catch(error => {

        console.error('Error fetching the JSON data:', error);
      });
  }, []);

  const [showSubmenu, setShowSubmenu] = useState(false);

  // console.log("data2",navbarItems.mainItems)

  const [selectedDropdown, setSelectedDropdown] = useState(null);

  const handleDropdownClick = (id) => {
    if (selectedDropdown === id) {
      setSelectedDropdown(id);
    } else {
      setSelectedDropdown(id);
    }
  };
  const handleDropdownHover = (id) => {
    setSelectedDropdown(id);
  };
  const handleDropdownLeave = () => {
    setSelectedDropdown(null);
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const isScrolled = window.pageYOffset > 50;
      setScrolled(isScrolled);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory(); // Initialize useHistory

  // const refreshPage = () => {
  //   history.push('/');
  //   window.location.reload();
  // };

  const getCategoryUrl = (name) => {
    switch (name) {
      case 'Laptop':
        return '/category/laptop';
      case 'AC':
        return '/category/ac';

      case 'Component':
        return '/category/component';
      case 'Monitor':
        return '/category/monitor';
      case 'Motherboard':
        return '/category/motherboard';

      case 'Speaker':
        return '/category/speaker';
      case 'Gaming':
        return '/category/gaming';


      case 'Graphics Card':
        return '/category/graphics card';
      case 'Headset':
        return '/category/headset';
      case 'Mousepad':
        return '/category/mousepad';
      case 'Power Supply':
        return '/category/power supply';
      case 'Mouse':
        return '/category/mouse';
      case 'Network':
        return '/category/network';
      case 'Security':
        return '/category/security';
      case 'Printer':
        return '/category/printer';
      default:
        return '';
    }
  };

  const getSubItemUrl = (name) => {
    switch (name) {
      case 'DVD-RW':
        return '/category/dvdrw';
      case 'Console':
        return '/category/console';
      case 'Gaming Chair':
        return '/category/gaming chair';
      case 'CC Camera':
        return '/category/cc camera';
      case 'Router':
        return '/category/router';
      case 'Processor':
        return '/category/processor';
      case 'Cpu Cooler':
        return '/category/cpu cooler';
      case 'Case':
        return '/category/case';
      default:
        return '';
    }
  };
  return (
    <header>

      <Helmet>
        <meta name="google-site-verification" content="rhDtn8yNyCUZ1QGjZHdMC44Yto3teREnwYQ9xnjP0pU" />
      </Helmet>
      <div className=" filter_off border-0 bd-example">
        <MenuSmall />
      </div>
      <Navbar
        bg="light"
        variant="dark"
        expand="lg"
        collapseOnSelect
        className="nav_bb filter_on"
      >
        <Container>
          {/* <LinkContainer to="/" onClick={refreshPage}>
      <Navbar.Brand>
        <img className='img-fluid logo-width' src="../images/logo.jpg" alt="Logo" />
      </Navbar.Brand>
    </LinkContainer> */}
          <LinkContainer to="/" >
            <Navbar.Brand>
              <img className='img-fluid logo-width' src="../images/logo.jpg" alt="Logo" />
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Route render={({ history }) => <SearchBox className="nav_formcontrol" history={history} />} />
            {/* <Route
              render={({ history }) => (
                <InputGroup className="width_nav_fixed ">
                  <Form.Control
                    placeholder="Search"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    history={history}
                    className="nav_serch_form"
                  />
                  <InputGroup.Text id="basic-addon2" className="nav_serch_btn">
                    <i class="fas fa-search"></i>
                  </InputGroup.Text>
                </InputGroup>
              )}
            /> */}
            <Nav className="ml-auto d-flex  align_for">
              <div className="padding_all marginTop border-0 bd-example">
                <CartView />
              </div>

              {userInfo ? (<Nav.Link className="mr-3 width_100">
                <div class="d-flex ">
                  <div class="flex-shrink-0">
                    <i className='fas fa-user  admin_ico mr-2 mt-2'></i>
                  </div>
                  <div class="flex-grow-1 ms-3 line_height">
                    <NavDropdown
                      className="nav_username mr-1"
                      title={userInfo.name}
                      id="username"
                    >
                      <LinkContainer to="/profile">
                        <NavDropdown.Item className="color_black">
                          Profile
                        </NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Item
                        className="color_black"
                        onClick={logoutHandler}
                      >
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown> <br></br>
                    <span className='font_customize'> Your Account </span>
                  </div>

                </div></Nav.Link>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link className="mr-3 marginTop">
                    <div class="d-flex ">
                      <div class="flex-shrink-0">
                        <i className='fas fa-user  admin_ico mr-2 mt-2'></i>
                      </div>
                      <div class="flex-grow-1 ms-3">
                        <span>Account</span> <br></br>
                        <span className='font_customize'> Log in/Register </span>
                      </div>
                    </div>
                  </Nav.Link>
                </LinkContainer>
              )}

              {userInfo && userInfo.isAdmin && (<Nav.Link className=" marginTop">
                <div class="d-flex ">
                  <div class="flex-shrink-0">
                    <i className='fas fa-user  admin_ico mr-2 mt-2'></i>
                  </div>
                  <div class="flex-grow-1 ms-3 line_height">
                    <NavDropdown title="Admin Panel" id="adminmenu" className="mr-1">
                      <LinkContainer to="/admin/userlist">
                        <NavDropdown.Item className="color_black">
                          Users
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/productlist">
                        <NavDropdown.Item className="color_black">
                          Products
                        </NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/orderlist">
                        <NavDropdown.Item className="color_black">
                          Orders
                        </NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to="/admin/offerslist">
                        <NavDropdown.Item className="color_black">
                          Offers
                        </NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to="/admin/notice/list">
                        <NavDropdown.Item className="color_black">
                          Notice
                        </NavDropdown.Item>
                      </LinkContainer>

                    </NavDropdown><br></br>
                    <span className='font_customize'> Your Account </span>
                  </div>

                </div></Nav.Link>
              )}
              {/* <LinkContainer to="PcBuilderScreen">
                <Button className="btn btn-primary nav_btn">PC Builder</Button>
              </LinkContainer> */}
            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="">
        <div className={`full_nav ${scrolled ? "scrolled" : ""}`}>
          <Container>
            <Navbar expand="sm" className="pt-1 pb-1 nav_ber  ">
              <Container className="justify-content-center">
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                  <Nav className="mx-auto mr-0 my-lg-0 nav_text" navbarScroll>
                    {jsonData && jsonData.mainItems ? (
                      jsonData.mainItems.map((item) => (
                        <div
                          key={item.id}
                          id="navbarScrollingDropdown"
                          onClick={() => handleDropdownClick(item.id)}
                          onMouseEnter={() => handleDropdownHover(item.id)}
                          onMouseLeave={handleDropdownLeave}
                          className="mr-0 pt-0 pb-0 nav_link"
                        >
                          <NavDropdown.Item className={`mr-0 pt-0 pb-0 nav_link ${selectedDropdown === item.id ? 'open' : ''}`}>
                            <Link
                              className="mr-0 pt-0 pb-0 nav_web_link"

                              to={getCategoryUrl(item.name)}
                            >
                              <div className="d-flex justify-content-between">
                                <span style={{ color: selectedDropdown === item.id ? 'black' : 'white' }}>{item.name}</span>
                                {item.subItems && item.subItems.length > 0 && (
                                  <i className="fas fa-caret-right submenu_icon"></i>
                                )}
                              </div>
                            </Link>
                          </NavDropdown.Item>

                          {selectedDropdown === item.id && item.subItems && (
                            <div className="sub_navLink">
                              {item.subItems.map((subItem) => (
                                <div key={subItem.url} className="dropdown-item-container bg_white">
                                  <Link
                                    key={subItem.name}
                                    className="mr-0 pt-0 pb-0 dropdown-item nav_web_link"
                                    to={getSubItemUrl(subItem.name)}
                                  >
                                    <div className="d-flex justify-content-between">
                                      <span>{subItem.name}</span>
                                      {subItem.subItems && subItem.subItems.length > 0 && (
                                        <i className="fas fa-caret-right submenu_icon"></i>
                                      )}
                                    </div>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <p>Loading...</p>
                    )}
                  </Nav>

                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Container>
        </div>
      </div>
    </header>
  );
};

export default Header;
